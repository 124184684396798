import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CheckoutContext, DispatchContext } from "../../context/CheckoutContext";
import paycash from "../../assets/img/logos/paycash-logo.svg";
import Barcode from 'react-barcode';

import "./styles.scss";

export const PayCashPaymentForm = (props) => {
  const { id } = useContext(CheckoutContext);
  const { data } = useContext(CheckoutContext);
  const { getCheckout } = useContext(DispatchContext);
  const { t } = useTranslation();

  const reference = data.externalRefs.reference;
  const countryCode = data.externalRefs.countryCode;

  const logosSrc = ({
    "CO": "https://paycash-storage.s3.amazonaws.com/PCDocs/COL/colombia.jpg",
    "CR": "https://paycash-storage.s3.amazonaws.com/PCDocs/CRI/costarica.jpg",
    "EC": "https://paycash-storage.s3.amazonaws.com/PCDocs/ECU/ecuador.jpg",
    "MX": "https://paycash-storage.s3.amazonaws.com/PCDocs/MEX/mexico.jpg",
    "PE": "https://paycash-storage.s3.amazonaws.com/PCDocs/PER/peru.jpg",
  })[countryCode] ?? "";

  const paymentInstructions = ({
    "CO": [],
    "CR": [],
    "EC": [],
    "MX": [
      { key: "Grupo Walmart", value: "Solicitar servicio 198" },
      { key: "BBVA Practicaja", value: "Convenio CIE1420712" },
      { key: "HSBC", value: "Convenio 7755" },
      { key: "SANTANDER", value: "Convenio 7292" },
      { key: "BANORTE", value: "Convenio 3724" },
      { key: "TELECOMM", value: "Convenio 3724" },
      { key: "Otros", value: "Solicitar pago de servicio PayCash" },
    ],
    "PE": [],
  })[countryCode] ?? [];

  useEffect(() => {
    const timeoutId = setTimeout(
      function () {
        if (id) {
          getCheckout(id);
          clearTimeout(timeoutId);
        }
      }, props.checkInterval);

    return (
      () => clearTimeout(timeoutId)
    );
  }, [id, props.checkInterval, getCheckout]);

  useEffect(() => {
    if (id) {
      const eventListener = new EventSource(`/sse/${id}`);

      eventListener.onmessage = () => {
        eventListener.close();
        getCheckout(id);
      };

      eventListener.onerror = () => {
        eventListener.close();
      };

      return (() => {
        eventListener.close();
      })
    }
  }, [id, getCheckout]);

  return (
    <>
      <div className="payment-details">
        <img src={paycash} alt={"PayCash"} />

        <Barcode value={reference} displayValue={false} />

        <div className="field-container">
          <div className="field-label">{t("payCashReference")}</div>
          <div className="field-data">
            <div>{reference}</div>
            <div className="icon-copy" onClick={() => navigator.clipboard.writeText(reference)}></div>
          </div>
        </div>

        <img className="img-logos" src={logosSrc} alt={"Logos"} />

        <div className="payment-intructions">
          {paymentInstructions.map((data, idx) => (
            <div className="payment-intruction">
              <span>{data.key}:</span>
              <span>{data.value}</span>
            </div>
          ))}
        </div>
      </div>

      {data.showBackToShopButton && (<a className="link-return-shop upi-button" href={data.returnUrl}>{t("backToShopFromInstructionPageButtonLabel")}</a>)}
    </>
  );
};
