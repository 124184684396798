import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  CheckoutContext,
  DispatchContext,
} from "../../context/CheckoutContext";
import { OrderDetails } from "../Components";
import { formatAmount } from "../../utils/CurrencyFormatter";

import "./styles.scss";

export const Confirmation = (props) => {
  const { data } = useContext(CheckoutContext);
  const { confirmRequest } = useContext(DispatchContext);
  const { t } = useTranslation();

  return (
    <div>
      <OrderDetails hideAmount={true} />
      <div className="confirmation-wrapper">
        <p className="confirm-message">{t("ConfirmationMessage")}</p>
        {data.customerAmount && data.customerCurrency && (
          <div>
            <p className="confirm-amount-currency">
              {t("depositAmountLabel")}&nbsp;
              {formatAmount(data.customerAmount, data.customerCurrency)}
            </p>
          </div>
        )}
        {data.commission && (
          <div>
            <p className="confirm-amount-currency">
              {t("commissionAmountLabel")}&nbsp;
              {formatAmount(data.commission, data.customerCurrency)}
            </p>
          </div>
        )}
        {data.amount && (
          <div>
            <p className="confirm-amount-currency">
              {data.customerAmount && t("debitAmountLabel")}&nbsp;
              {formatAmount(data.amount, data.currency)}
            </p>
          </div>
        )}
      </div>

      <input
        className="button-submit"
        type="button"
        onClick={() => confirmRequest()}
        value={t("ButtonConfirmLabel")}
      />
    </div>
  );
};
