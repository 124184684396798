import React, { useContext } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CheckoutContext } from "../../../../context/CheckoutContext";
import { formatAmount } from "../../../../utils/CurrencyFormatter";

import "./style.scss";

export const AmountsRadio = ({ onManualClick }) => {
  const { data, method } = useContext(CheckoutContext);
  const { register, errors } = useFormContext();
  const { t } = useTranslation();

  return (
    <>
      <div className="wrapper-amount-radios">
        {method.predefinedAmounts.map((amount) => (
          <div key={amount} className="radio-amount">
            <input
              id={amount}
              name="amount"
              type="radio"
              value={amount}
              ref={register({ required: t("AmountRequiredLabel") })}
            />
            <label htmlFor={amount}>
              {formatAmount(amount, data.currency)}
            </label>
          </div>
        ))}
      </div>
      <div onClick={() => onManualClick()}>
        <p className="button-toggle-amount-input">{t("ManualAmountLabel")}</p>
      </div>
      {errors.amount && (
        <p className="error-validation">{errors.amount.message}</p>
      )}
    </>
  );
};
