import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// the translations
// (tip moveto JSON file and import)
const resources = {
  en: {
    translation: {
      PAYID: 'PayID',
      payIdInstructionBankPayment:
        'Go to your bank app or portal and make the payment using the email below',
      transferExactAmount: '*Please transfer the exact amount.',
      payIdSupportMessage:
        'In case of issues including duplicate transactions, please contact {{merchantSupportEmail}} for quick resolution.',
      payingTo: 'Paying to',
      merchantSupportEmail: 'Merchant support email',
      cardHolderLabel: 'Holder name',
      cardHolderPlaceHolder: 'Name on the card',
      cardPanLabel: 'Card Number',
      cardPanPlaceHolder: '0000 0000 0000 0000',
      CardExpiryLable: 'Expiry Date',
      CardExpiryPlaceholder: 'MM/YY',
      CardSecurityCodeLabel: 'Security Code',
      CardSecurityPlaceholder: 'CVV',
      AmountLabel: 'Amount',
      AmountPlaceholder: 'Enter the amount',
      ButtonSubmitLabel: 'Pay now',
      SelectPaymentMethodLabel: 'Select Payment Method',
      SelectPredefineAmountLabel: 'Select an Amount',
      ManualAmountLabel: 'Use a Different Amount',
      customerCitizenshipCountryCodeLabel: 'Citizenship',
      customerCitizenshipCountryCodePlaceHolder: 'Country of citizenship',
      billingCountryCodeLabel: 'Country',
      billingCountryCodePlaceHolder: 'Select Country',
      customerFirstNameLabel: 'First name',
      customerFirstNamePlaceHolder: 'First name',
      customerLastNameLabel: 'Last name',
      customerLastNamePlaceHolder: 'Last name',
      billingAddressLine1Label: 'Address',
      billingAddressLine1PlaceHolder: 'Address line 1',
      billingCityLabel: 'City',
      billingCityPlaceHolder: 'City',
      billingStateLabel: 'State',
      billingStatePlaceHolder: 'State',
      billingPostalCodeLabel: 'Postal code',
      billingPostalCodePlaceHolder: 'Postal code',
      customerDateOfBirthLabel: 'Date of birth',
      customerDateOfBirthPlaceHolder: 'Date of birth',
      customerEmailLabel: 'Email',
      customerEmailPlaceHolder: 'mail@mail.com',
      customerPhoneLabel: 'Phone number',
      customerPhonePlaceHolder: 'Enter Phone number',
      customerPersonalIdLabel: 'Personal Identification',
      customerPersonalIdPlaceHolder: 'Identification number',
      billingAddressLine2Label: 'Address line 2',
      billingAddressLine2PlaceHolder: 'Address line 2',
      CardNumberRequired: 'Card number is required',
      CardNumberInvalid: 'Invalid card number',
      CardExpiryDateRequired: 'Expire date is required',
      CVVRequired: 'Security code is required',
      customerAccountNumberLabel: 'Account Number',
      customerAccountNumberPlaceHolder: 'Enter your account',
      AmountRequiredLabel: 'Amount is required',
      AmountInvalidLabel: 'Amount is invalid',
      CancelLabel: 'Cancel',
      PhoneNumberRequired: 'Phone number is required',
      PhoneNumberInvalid: 'Phone number is invalid',
      PaymentMethodNotAvailableLabel: 'Payment methods not available',
      ButtonConfirmLabel: 'Confirm',
      ButtonConfirmCascadingLabel: 'Try again using another provider',
      ButtonConfirmCascadingTimeoutLabel: 'Will try another provider in ',
      cascadingInstructions:
        "Your transaction was declined, but don't worry. We have another provider to try.",
      BASIC_CARD: 'Credit Card',
      SKRILL: 'Skrill',
      FLEXEPIN: 'Flexepin',
      MACROPAY: 'Macropay',
      PAYPAL: 'PayPal',
      NETELLER: 'Neteller',
      PAYRETAILERS: 'PayRetailers',
      TRUSTPAYMENTS: 'TrustPayments',
      LOCALPAYMENT: 'Local Payment',
      MONNET: 'Monnet',
      PAYMAXIS: 'PayMaxis',
      GATE8TRANSACT: 'Gate8 Transact',
      TINK: 'Pay with your bank',
      VOLT: 'Pay with your bank',
      B2BINPAY: 'B2BinPay (Crypto)',
      CLICK: 'Pay with your bank',
      MONETIX: 'Monetix',
      PERFECTMONEY: 'Perfect Money',
      KESSPAY: 'KessPay',
      CRYPTO: 'Crypto Deposit',
      BILLLINE: 'Bill Line',
      NGENIUS: 'N-Genius',
      ASTROPAY: 'AstroPay',
      ALYCEPAY: 'AlycePay',
      PIX: 'Pix',
      UPI: 'UPI',
      PAYTM: 'Paytm',
      NETBANKING: 'Net Banking',
      BOLETO: 'Boleto',
      FINRAX: 'Crypto Deposit',
      SPOYNT: 'Spoynt',
      XINPAY: 'XinPay',
      OMNIMATRIX: 'Omni Matrix',
      DPOPAY: 'DPO Pay',
      EXTERNAL_HPP: 'Payment Page',
      XANPAY: 'XanPay',
      INRPAY: 'InrPay',
      ARI10: 'Ari10',
      SOFORT: 'Sofort',
      GIROPAY: 'GiroPay',
      PAYSAFECARD: 'Paysafecard',
      OPEN_BANKING: 'Open Banking',
      SPEI: 'SPEI Mexico',
      PAYCASH: 'PayCash',
      RAPIPAGO: 'Rapipago',
      PAGOFACIL: 'Pago Fácil',
      MOBILE_MONEY: 'Mobile Money',
      INTERAC: 'Interac',
      PICPAY: 'PicPay',
      MOLLIE: 'Mollie',
      ConfirmationMessage: 'Please confirm the amount:',
      customSelectPlaceHolderpaymentMethod: 'Select method',
      customSelectPlaceHolderdocumentType: 'Select document type',
      customSelectPlaceHoldercryptoCurrency: 'Select crypto currency',
      customSelectPlaceHolderbankId: 'Select your bank',
      paymentMethod: 'Payment Method',
      documentType: 'Document type',
      documentNumber: 'Document number',
      cryptoCurrency: 'Crypto currency',
      accountNumber: 'Account number',
      pixKeyType: 'Type of bank account reference',
      customSelectPlaceHolderpixKeyType:
        'Select type of bank account reference',
      pixKey: 'Bank account reference',
      bankId: 'Bank',
      networkProvider: 'Network provider',
      customSelectPlaceHoldernetworkProvider: 'Select your network provider',
      phoneNumber: 'Mobile phone number',
      maskedPanMessage: 'Amount will be deducted from :',
      endingIn: 'ending in',
      descriptionLabel: 'Description:',
      invalidCVV: 'Invalid CVV',
      CardExpiryDateInvalid: 'Invalid or expired date',
      IN_PROGRESS: 'IN PROGRESS',
      CANCELLED: 'CANCELLED',
      DECLINED: 'DECLINED',
      COMPLETED: 'COMPLETED',
      backToShopButtonLabel: 'Return to shop',
      backToShopFromInstructionPageButtonLabel: 'Return to shop after payment',
      minAmountLabel: 'Min:',
      maxAmountLabel: 'Max:',
      depositAmountLabel: 'Deposit amount:',
      commissionAmountLabel: 'Commission:',
      debitAmountLabel: 'Debit amount:',
      validationRequired: 'The {{fieldName}} field is required',
      validationPattern: 'Invalid format',
      validationMaxLength: 'The maximum length is {{maxLength}} characters',
      cryptoWarningMessage_1:
        'If you send any other amount, the system will accept it anyway',
      cryptoWarningMessage_2:
        'Please ensure you use both the address and destination tag when depositing',
      sendAmountInToAddress:
        'Send {{amount}} in one payment to the following address: {{address}}',
      destinationTag: 'Destination Tag: {{destinationTag}}',
      copyToClipboard: 'Copy address to clipboard',
      copied: 'Copied',
      scanAddressOr: 'Scan the wallet address or',
      customerCardId: 'Select card',
      AddNewCardLabel: 'Add new card',
      useSavedCardLabel: 'Use saved card',
      saveThisCardLabel: 'Save this card',
      selectCardValidationMessage:
        'Please select a card or use a different card',
      removeConfirmation: 'Remove Confirmation',
      removeCardConfirmMessage: 'Are you sure you want to delete this card?',
      delete: 'Delete',
      cancel: 'Cancel',
      upiVpa: 'UPI VPA',
      upiRequestQrButton: 'Scan the QR code using your UPI apps',
      upiVpaLabel: 'UPI ID',
      upiVpaPlaceholder: 'Enter UPI ID',
      upiVpaHint: 'UPI ID should be in the format username@upihandle',
      upiSubmitVpaButton: 'Verify & Pay',
      upiQrCodeHint: 'Scan & Pay with you UPI apps',
      upiVpaPaymentInstruction:
        'We have successfully initiated a payment. Please open your UPI app and confirm the transaction.',
      speiCompanyName: 'Beneficiary',
      speiBankAccountNumber: 'CLABE',
      speiBankName: 'Destination Bank',
      speiReferenceNumber: 'Reference',
      payCashReference: 'Reference'
    }
  },
  ru: {
    translation: {
      cardHolderLabel: 'Имя на карте',
      cardHolderPlaceHolder: 'Имя на карте',
      cardPanLabel: 'Номер карты',
      cardPanPlaceHolder: 'Номер карты',
      CardExpiryLable: 'ММ/ГГ',
      CardExpiryPlaceholder: 'ММ/ГГ',
      CardSecurityCodeLabel: 'CVV/CVC',
      CardSecurityPlaceholder: 'CVV',
      AmountLabel: 'Сумма',
      AmountPlaceholder: 'Сумма',
      ButtonSubmitLabel: 'Оплатить',
      SelectPaymentMethodLabel: 'Выберите способ оплаты',
      SelectPredefineAmountLabel: 'Выбрать сумму',
      ManualAmountLabel: 'Выбрать другую сумму',
      customerCitizenshipCountryCodeLabel: 'Гражданство',
      customerCitizenshipCountryCodePlaceHolder: 'Гражданство',
      billingCountryCodeLabel: 'Страна',
      billingCountryCodePlaceHolder: 'Выберите страну',
      customerFirstNameLabel: 'Имя',
      customerFirstNamePlaceHolder: 'Имя',
      customerLastNameLabel: 'Фамилия',
      customerLastNamePlaceHolder: 'Фамилия',
      customerPersonalIdLabel: 'Номер паспорта',
      customerPersonalIdPlaceHolder: 'Номер паспорта',
      billingAddressLine1Label: 'Адрес',
      billingAddressLine1PlaceHolder: 'Адрес',
      billingCityLabel: 'Город',
      billingCityPlaceHolder: 'Город',
      billingStateLabel: 'Регион',
      billingStatePlaceHolder: 'Регион',
      billingPostalCodeLabel: 'Почтовый индекс',
      billingPostalCodePlaceHolder: 'Почтовый индекс',
      customerDateOfBirthLabel: 'Дата рождения',
      customerDateOfBirthPlaceHolder: 'Дата рождения',
      customerEmailLabel: 'Email',
      customerEmailPlaceHolder: 'Email',
      customerPhoneLabel: 'Телефон',
      customerPhonePlaceHolder: 'Телефон',
      billingAddressLine2Label: 'Адрес, строка 2',
      billingAddressLine2PlaceHolder: 'Адрес, строка 2',
      customerAccountNumberLabel: 'Номер счета',
      customerAccountNumberPlaceHolder: 'Номер счета',
      AmountRequiredLabel: 'Не выбрана сумма',
      AmountInvalidLabel: 'Неверная сумма',
      CancelLabel: 'Отменить',
      CardNumberRequired: 'Номер карты обязателен',
      PhoneNumberRequired: 'Не выбран номер телефона',
      PhoneNumberInvalid: 'Неверный номер телефона',
      PaymentMethodNotAvailableLabel: 'Нет доступных способов платежа',
      ButtonConfirmLabel: 'Подтвердить',
      ButtonConfirmCascadingLabel: 'Повторить через другого провайдера',
      ButtonConfirmCascadingTimeoutLabel: 'Повторить через ',
      cascadingInstructions:
        'Транзакция была отклонена, но можно попробовать еще раз через другого провайдера',
      TINK: 'Платить в своем банке',
      VOLT: 'Платить в своем банке',
      CLICK: 'Платить в своем банке',
      CRYPTO: 'Крипто депозит',
      ConfirmationMessage: 'Пожалуйста, подтвердите сумму:',
      customSelectPlaceHolderpaymentMethod: 'Выберите метод',
      customSelectPlaceHolderdocumentType: 'Выберите тип документа',
      customSelectPlaceHoldercryptoCurrency: 'Выберите крипто валюту',
      paymentMethod: 'Способ платежа',
      documentType: 'Тип документа',
      documentNumber: 'Номер документа',
      cryptoCurrency: 'Крипто валюта',
      accountNumber: 'Номер счета',
      pixKey: 'Ключ Pix',
      maskedPanMessage: 'Сумма будет списана с карты:',
      endingIn: 'заканчивается на',
      descriptionLabel: 'Описание:',
      invalidCVV: 'Неверный CVV',
      CardExpiryDateInvalid: 'Неверный срок дейстия',
      IN_PROGRESS: 'ОБРАБАТЫВАЕТСЯ',
      CANCELLED: 'ОТМЕНЕН',
      DECLINED: 'ОТКЛОНЕН',
      COMPLETED: 'УСПЕШЕН',
      backToShopButtonLabel: 'Вернуться на сайт',
      backToShopFromInstructionPageButtonLabel:
        'Вернуться на сайт после платежа',
      minAmountLabel: 'Min:',
      maxAmountLabel: 'Max:',
      depositAmountLabel: 'Сумма к зачислению:',
      commissionAmountLabel: 'Комиссия:',
      debitAmountLabel: 'Сумма списания:',
      validationRequired: 'Поле {{fieldName}} обязательно',
      validationMaxLength: 'Максимальная длина {{maxLength}} символов',
      cryptoWarningMessage_1:
        'Если Вы отправите другую сумму, система все равно обработает оплату',
      cryptoWarningMessage_2:
        'Убедитесь, что вы указали и адрес и destination tag',
      sendAmountInToAddress:
        'Отправьте {{amount}} одним платежом на следующий адрес: {{address}}',
      copyToClipboard: 'Скопируйте адрес',
      copied: 'Скопировано',
      scanAddressOr: 'Сканируйте адрес кошелька или'
    }
  },
  it: {
    translation: {
      cardHolderLabel: 'Nome titolare',
      cardHolderPlaceHolder: 'Nome sulla carta',
      cardPanLabel: 'Numero carta',
      cardPanPlaceHolder: '0000 0000 0000 0000',
      CardExpiryLable: 'Data di scadenza',
      CardExpiryPlaceholder: 'MM/AA',
      CardSecurityCodeLabel: 'Codice di sicurezza',
      CardSecurityPlaceholder: 'CVV',
      AmountLabel: 'Importo',
      AmountPlaceholder: "Digita l'importo",
      ButtonSubmitLabel: 'Paga ora',
      SelectPaymentMethodLabel: 'Seleziona un metodo di pagamento',
      SelectPredefineAmountLabel: 'Seleziona un importo',
      ManualAmountLabel: 'Usa un importo diverso',
      customerCitizenshipCountryCodeLabel: 'Cittadinanza',
      customerCitizenshipCountryCodePlaceHolder: 'Paese di cittadinanza',
      billingCountryCodeLabel: 'Stato',
      billingCountryCodePlaceHolder: 'Seleziona Stato',
      customerFirstNameLabel: 'Nome',
      customerFirstNamePlaceHolder: 'Nome',
      customerLastNameLabel: 'Cognome',
      customerLastNamePlaceHolder: 'Cognome',
      billingAddressLine1Label: 'Indirizzo',
      billingAddressLine1PlaceHolder: 'Indirizzo riga 1',
      billingCityLabel: 'Città',
      billingCityPlaceHolder: 'Città',
      billingStateLabel: 'Stato',
      billingStatePlaceHolder: 'Stato',
      billingPostalCodeLabel: 'Codice postale',
      billingPostalCodePlaceHolder: 'Codice postale',
      customerDateOfBirthLabel: 'Data di nascita',
      customerDateOfBirthPlaceHolder: 'Data di nascita',
      customerEmailLabel: 'Indirizzo e-mail',
      customerEmailPlaceHolder: 'mail@mail.com',
      customerPhoneLabel: 'Numero di telefono',
      customerPhonePlaceHolder: 'Digita un numero di telefono',
      customerPersonalIdLabel: 'Identificativo personale',
      customerPersonalIdPlaceHolder: 'Numero identificativo',
      billingAddressLine2Label: 'Indirizzo riga 2',
      billingAddressLine2PlaceHolder: 'Indirizzo riga 2',
      CardNumberRequired: 'Inserisci il numero della carta',
      CardNumberInvalid: 'Numero carta non valido',
      CardExpiryDateRequired: 'Inserisci la data di scadenza',
      CVVRequired: 'Inserisci il codice di sicurezza',
      customerAccountNumberLabel: 'Numero account',
      customerAccountNumberPlaceHolder: 'Digita il tuo account',
      AmountRequiredLabel: "Inserisci l'importo",
      AmountInvalidLabel: "L'importo non è valido",
      CancelLabel: 'Annulla',
      PhoneNumberRequired: 'Inserisci il numero di telefono',
      PhoneNumberInvalid: 'Il numero di telefono non è valido',
      PaymentMethodNotAvailableLabel: 'Metodo di pagamento non disponibile',
      ButtonConfirmLabel: 'Conferma',
      ButtonConfirmCascadingLabel: 'Try again using another provider',
      cascadingInstructions:
        "Your transaction was declined, but don't worry. We have another provider to try.",
      BASIC_CARD: 'Carta di credito',
      ConfirmationMessage: "Conferma l'importo convertito:",
      customSelectPlaceHolderpaymentMethod: 'Seleziona metodo',
      customSelectPlaceHolderdocumentType: 'Seleziona il tipo di documento',
      customSelectPlaceHoldercryptoCurrency: 'Select crypto currency',
      paymentMethod: 'Metodo di pagamento',
      documentType: 'Tipo di documento',
      documentNumber: 'Numero documento',
      cryptoCurrency: 'Crypto currency',
      accountNumber: 'Numero account',
      pixKey: 'Pix key',
      maskedPanMessage: "L'importo verrà detratto da:",
      endingIn: 'termina con',
      descriptionLabel: 'Descrizione:',
      invalidCVV: 'CVV non valido',
      CardExpiryDateInvalid: 'Data di scadenza non valida',
      IN_PROGRESS: 'IN CORSO',
      CANCELLED: 'ANNULLATA',
      DECLINED: 'DECLINATA',
      COMPLETED: 'COMPLETATO',
      backToShopButtonLabel: 'Torna al negozio',
      backToShopFromInstructionPageButtonLabel: 'Return to shop after payment',
      minAmountLabel: 'Min:',
      maxAmountLabel: 'Max:',
      depositAmountLabel: 'Deposit amount:',
      commissionAmountLabel: 'Commission:',
      debitAmountLabel: 'Debit amount:'
    }
  },
  fr: {
    translation: {
      cardHolderLabel: 'Nom du titulaire',
      cardHolderPlaceHolder: 'Nom sur la carte',
      cardPanLabel: 'Numéro de carte',
      cardPanPlaceHolder: '0000 0000 0000 0000',
      CardExpiryLable: "Date d'expiration",
      CardExpiryPlaceholder: 'MM/AA',
      CardSecurityCodeLabel: 'Code de sécurité',
      CardSecurityPlaceholder: 'CVV',
      AmountLabel: 'Montant',
      AmountPlaceholder: 'Entrez le montant',
      ButtonSubmitLabel: 'Payer maintenant',
      SelectPaymentMethodLabel: 'Choisissez le mode de paiement',
      SelectPredefineAmountLabel: 'Choisissez un montant',
      ManualAmountLabel: 'Utiliser un montant différent',
      customerCitizenshipCountryCodeLabel: 'Nationalité',
      customerCitizenshipCountryCodePlaceHolder: 'Pays de nationalité',
      billingCountryCodeLabel: 'Pays',
      billingCountryCodePlaceHolder: 'Choisissez le pays',
      customerFirstNameLabel: 'Prénom',
      customerFirstNamePlaceHolder: 'Prénom',
      customerLastNameLabel: 'Nom de famille',
      customerLastNamePlaceHolder: 'Nom de famille',
      billingAddressLine1Label: 'Adresse',
      billingAddressLine1PlaceHolder: 'Adresse ligne 1',
      billingCityLabel: 'Ville',
      billingCityPlaceHolder: 'Ville',
      billingStateLabel: 'État',
      billingStatePlaceHolder: 'État',
      billingPostalCodeLabel: 'Code postal',
      billingPostalCodePlaceHolder: 'Code postal',
      customerDateOfBirthLabel: 'Date de naissance',
      customerDateOfBirthPlaceHolder: 'Date de naissance',
      customerEmailLabel: 'Email',
      customerEmailPlaceHolder: 'mail@mail.com',
      customerPhoneLabel: 'Numéro de téléphone',
      customerPhonePlaceHolder: 'Entrez un numéro de téléphone',
      customerPersonalIdLabel: 'PIN',
      customerPersonalIdPlaceHolder: 'Code PIN',
      billingAddressLine2Label: 'Adresse ligne 2',
      billingAddressLine2PlaceHolder: 'Adresse ligne 2',
      CardNumberRequired: 'Un numéro de carte est requis',
      CardNumberInvalid: 'NUméro de carte invalide',
      CardExpiryDateRequired: "Une date d'expiration est requise",
      CVVRequired: 'Un code de sécurité est requis',
      customerAccountNumberLabel: 'Numéro de compte',
      customerAccountNumberPlaceHolder: 'Entrez votre numéro de compte',
      AmountRequiredLabel: 'Un montant est requis',
      AmountInvalidLabel: 'Le montant est invalide',
      CancelLabel: 'Annuler',
      PhoneNumberRequired: 'Un numéro de téléphone est requis',
      PhoneNumberInvalid: 'Le numéro de téléphone est invalide',
      PaymentMethodNotAvailableLabel: 'Mode de paiement non disponible',
      ButtonConfirmLabel: 'Confirmer',
      ButtonConfirmCascadingLabel: 'Try again using another provider',
      cascadingInstructions:
        "Your transaction was declined, but don't worry. We have another provider to try.",
      BASIC_CARD: 'Carte de crédit',
      ConfirmationMessage: 'Veuillez confirmer le montant converti:',
      customSelectPlaceHolderpaymentMethod: 'Choisissez le mode de paiement',
      customSelectPlaceHolderdocumentType: 'Choisissez le type de document',
      customSelectPlaceHoldercryptoCurrency: 'Select crypto currency',
      paymentMethod: 'Mode de paiement',
      documentType: 'Type de document',
      documentNumber: 'Numéro du document',
      cryptoCurrency: 'Crypto currency',
      accountNumber: 'Numéro de compte',
      pixKey: 'Pix key',
      maskedPanMessage: 'Le montant sera déduit de :',
      endingIn: 'finissant en',
      descriptionLabel: 'Description:',
      invalidCVV: 'CVV invalide',
      CardExpiryDateInvalid: "Date d'expiration invalide",
      IN_PROGRESS: 'EN COURS',
      CANCELLED: 'ANNULÉ',
      DECLINED: 'REFUSÉE',
      COMPLETED: 'COMPLÉTÉ',
      backToShopButtonLabel: 'Retour à la boutique',
      backToShopFromInstructionPageButtonLabel: 'Return to shop after payment',
      minAmountLabel: 'Min:',
      maxAmountLabel: 'Max:',
      depositAmountLabel: 'Deposit amount:',
      commissionAmountLabel: 'Commission:',
      debitAmountLabel: 'Debit amount:'
    }
  }
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: {
      order: ['navigator']
    },
    resources,
    fallbackLng: 'en',
    keySeparator: false,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
