import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import './styles.scss';
import {
  CheckoutContext,
  DispatchContext
} from '../../context/CheckoutContext';

export const Cascading = () => {
  const { data, method } = useContext(CheckoutContext);
  const { confirmCascading, cancelPayment } = useContext(DispatchContext);
  const { t } = useTranslation();

  const [useTimeOut] = useState(
    data.hasOwnProperty('cascadingConfirmationTimeout')
  );

  const [count, setCount] = useState(data.cascadingConfirmationTimeout);

  useEffect(() => {
    if (useTimeOut) {
      const i_id = setTimeout(() => {
        setCount(currCount => currCount - 1);
        if (count === 0) {
          confirmCascading();
        }
      }, 1000);

      return () => clearTimeout(i_id);
    }
  }, [confirmCascading, count, useTimeOut]);

  return (
    <>
      <section className="section-order">
        <div className="nav-grid">
          <div></div>
          {method && (
            <div className="payment-method-title">
              <span className="method-label">
                {typeof method === 'object'
                  ? t(method.paymentMethod)
                  : t(method)}
              </span>
            </div>
          )}
          <div>
            {data.showCancelButton && (
              <div
                className="button-cancel"
                onClick={() => cancelPayment()}
              ></div>
            )}
          </div>
        </div>
      </section>

      <h1 className={'title-state DECLINED'}>{t('DECLINED')}</h1>
      <div className="cascading-instructions">{t('cascadingInstructions')}</div>
      <i className="cascading-arrow"></i>

      {useTimeOut ? (
        <input
          className="button-submit"
          type="button"
          onClick={() => confirmCascading()}
          value={t('ButtonConfirmCascadingTimeoutLabel') + count}
        />
      ) : (
        <input
          className="button-submit"
          type="button"
          onClick={() => confirmCascading()}
          value={t('ButtonConfirmCascadingLabel')}
        />
      )}
    </>
  );
};
