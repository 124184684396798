import { formatPhoneNumberIntl } from 'react-phone-number-input';

export const buildBrowserInfoRequest = () => {
  const date = new Date();

  return {
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
    screenWidth: window.screen.width,
    screenHeight: window.screen.height,
    screenColorDepth: window.screen.colorDepth,
    navigatorLanguage: navigator.language,
    navigatorJavaEnabled: navigator.javaEnabled(),
    timezoneOffset: date.getTimezoneOffset()
  };
};

export const buildRequest = (formData, method, amount) => {
  let basicCard = null;

  if (method.paymentMethod === 'BASIC_CARD' && formData.cardNumber) {
    const expiryDate = formData.expiryDate.split('/');

    basicCard = {
      cardNumber: formData.cardNumber.replace(/\s/g, ''),
      cardholderName: formData.cardholderName,
      cardSecurityCode: formData.cardSecurityCode,
      expiryMonth: expiryDate[0],
      expiryYear: '20' + expiryDate[1],
      saveCard: formData.saveCard
    };
  }

  if (method.paymentMethod === 'BASIC_CARD' && formData.savedCardId) {
    basicCard = {
      customerCardId: formData.savedCardId,
      saveCard: formData.saveCard,
      cardSecurityCode: formData.cardSecurityCode
    };
  }

  const browserInfoRequest = buildBrowserInfoRequest();

  return {
    paymentMethod: method.paymentMethod,
    amount: amount ? amount : formData.amount.replace(/\s/g, ''),
    customerCitizenshipCountryCode: formData.customerCitizenshipCountryCode,
    billingCountryCode: formData.billingCountryCode,
    customerFirstName: formData.customerFirstName,
    customerLastName: formData.customerLastName,
    billingAddressLine1: formData.billingAddressLine1,
    billingCity: formData.billingCity,
    billingState: formData.billingState,
    billingPostalCode: formData.billingPostalCode,
    customerDateOfBirth: formData.customerDateOfBirth,
    customerEmail: formData.customerEmail,
    customerPhone: formattedPhoneNumber(formData.customerPhone),
    billingAddressLine2: formData.billingAddressLine2,
    customerAccountNumber: formData.customerAccountNumber,
    basicCard: basicCard,
    ...browserInfoRequest
  };
};

export const handleRedirection = async (formData, id) => {
  const form = document.createElement('form');

  form.action = formData.url;
  form.method = formData.redirectMode;

  if (Object.keys(formData.parameters).length !== 0) {
    Object.entries(formData.parameters).forEach(param => {
      const [key, value] = param;
      if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          let input = document.createElement('input');
          input.type = 'hidden';
          input.name = key;
          input.value = value[i];
          form.appendChild(input);
        }
      } else {
        let input = document.createElement('input');
        input.type = 'hidden';
        input.name = key;
        input.value = value;
        form.appendChild(input);
      }
    });

    document.body.appendChild(form);
  }

  await fetch(`/checkouts/${id}/confirm-redirect`, { method: 'POST' }).then(
    response => {
      if (response.ok) {
        if (formData.redirectMode === 'SIMPLE') {
          window.location.replace(formData.url);
        } else {
          form.submit();
        }
      } else {
        throw new Error(response.status);
      }
    }
  );
};

const formattedPhoneNumber = phoneNumber => {
  if (!phoneNumber) return null;

  let split = formatPhoneNumberIntl(phoneNumber).trim().split(' ');
  let code = split[0];
  let number = split.slice(1, split.length).join('');

  return code.replace('+', '') + ' ' + number;
};
