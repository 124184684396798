import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CheckoutContext, DispatchContext } from "../../context/CheckoutContext";
import { OrderDetails } from "../Components";

import "./styles.scss";

export const SpeiPaymentForm = (props) => {
  const { id } = useContext(CheckoutContext);
  const { data } = useContext(CheckoutContext);
  const { getCheckout } = useContext(DispatchContext);
  const { t } = useTranslation();

  const companyName = data.externalRefs.companyName;
  const bankAccountNumber = data.externalRefs.bankAccountNumber;
  const bankName = data.externalRefs.bankName;
  const referenceNumber = data.externalRefs.referenceNumber;

  useEffect(() => {
    const timeoutId = setTimeout(
      function () {
        if (id) {
          getCheckout(id);
          clearTimeout(timeoutId);
        }
      }, props.checkInterval);

    return (
      () => clearTimeout(timeoutId)
    );
  }, [id, props.checkInterval, getCheckout]);

  useEffect(() => {
    if (id) {
      const eventListener = new EventSource(`/sse/${id}`);

      eventListener.onmessage = () => {
        eventListener.close();
        getCheckout(id);
      };

      eventListener.onerror = () => {
        eventListener.close();
      };

      return (() => {
        eventListener.close();
      })
    }
  }, [id, getCheckout]);

  return (
    <>
      <OrderDetails />

      <div className="payment-details">
        <div className="field-container">
          <div className="field-label">{t("speiCompanyName")}</div>
          <div className="field-data">
            <div>{companyName}</div>
            <div className="icon-copy" onClick={() => navigator.clipboard.writeText(companyName)}></div>
          </div>
        </div>
        <div className="field-container">
          <div className="field-label">{t("speiBankAccountNumber")}</div>
          <div className="field-data">
            <div>{bankAccountNumber}</div>
            <div className="icon-copy" onClick={() => navigator.clipboard.writeText(bankAccountNumber)}></div>
          </div>
        </div>
        <div className="field-container">
          <div className="field-label">{t("speiBankName")}</div>
          <div className="field-data">
            <div>{bankName}</div>
            <div className="icon-copy" onClick={() => navigator.clipboard.writeText(bankName)}></div>
          </div>
        </div>
        <div className="field-container">
          <div className="field-label">{t("speiReferenceNumber")}</div>
          <div className="field-data">
            <div>{referenceNumber}</div>
            <div className="icon-copy" onClick={() => navigator.clipboard.writeText(referenceNumber)}></div>
          </div>
        </div>
      </div>

      {data.showBackToShopButton && (<a className="link-return-shop upi-button" href={data.returnUrl}>{t("backToShopFromInstructionPageButtonLabel")}</a>)}
    </>
  );
};
