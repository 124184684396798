import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CheckoutContext,
  DispatchContext
} from '../../context/CheckoutContext';
import './styles.scss';
import { OrderDetails } from '../Components';

export const PayID = ({ checkInterval }) => {
  const { id } = useContext(CheckoutContext);
  const {
    data: { externalRefs }
  } = useContext(CheckoutContext);
  const { getCheckout } = useContext(DispatchContext);
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);

  const addressToClipboard = () => {
    navigator.clipboard.writeText(externalRefs.payId);
    setCopied(true);
  };

  useEffect(() => {
    const timeoutId = setTimeout(function () {
      if (id) {
        getCheckout(id);
        clearTimeout(timeoutId);
      }
    }, checkInterval);

    return () => clearTimeout(timeoutId);
  }, [id, checkInterval, getCheckout]);

  useEffect(() => {
    if (id) {
      const eventListener = new EventSource(`/sse/${id}`);

      eventListener.onmessage = () => {
        eventListener.close();
        getCheckout(id);
      };

      eventListener.onerror = () => {
        eventListener.close();
      };

      return () => {
        eventListener.close();
      };
    }
  }, [id, getCheckout]);

  return (
    <div className="payId-layout">
      <OrderDetails />
      <div className="instruction-text">{t('payIdInstructionBankPayment')}</div>
      <div className="payId-block">
        <span>{externalRefs.payId}</span>
        <div className={`copy-button ${copied}`} onClick={addressToClipboard}>
          <i className="copy-icon"></i>
          <i className={`checkmark ${copied ? 'checkmark-copied' : ''}`}></i>
        </div>
      </div>
      <div className="payId-block">
        <div className="payId-detail">
          <span className="payId-detail-title">{t('payingTo')}</span>
          <span className="payId-detail-value">
            {externalRefs.merchantName}
          </span>
        </div>
      </div>
      <div className="instruction-text">{t('transferExactAmount')}</div>
      <div className="instruction-text">
        {t('payIdSupportMessage', {
          merchantSupportEmail: externalRefs.merchantSupportEmail
        })}
      </div>
    </div>
  );
};
