import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { CheckoutContext } from "../../../../context/CheckoutContext";
import { CleaveInput } from "../../../input/CleaveInput";
import { AmountsRadio } from "./AmoutsRadio";

import "./style.scss";

export const Amount = () => {
  const { data, method } = useContext(CheckoutContext);
  const [usePredefined, setPredefinedState] = useState(
    typeof method.predefinedAmounts !== "undefined"
  );

  const { t } = useTranslation();

  function switchToManual() {
    setPredefinedState(false);
  }

  function switchToPredefined() {
    setPredefinedState(true);
  }

  function required(value) {
    let error;
    if (!value) {
      error = `${t("AmountRequiredLabel")}`;
    }
    if (value < method.minAmount || value > method.maxAmount) {
      error = `${t("AmountInvalidLabel")}`;
    }
    return error || true;
  }

  return (
    <>
      {usePredefined ? (
        <div className="predefined-amounts">
          <label>{t("SelectPredefineAmountLabel")}</label>
          <div className="amounts">
            <AmountsRadio onManualClick={switchToManual} />
          </div>
        </div>
      ) : (
        <div>
          <CleaveInput
            options={{
              numeral: true,
              numeralDecimalMark: ".",
              delimiter: " ",
            }}
            name="amount"
            id="amount"
            label="AmountLabel"
            placeHolder="AmountPlaceholder"
            inputValidation={(value) => required(value)}
            currency={data.currency}
          />
          {(method.minAmount || method.maxAmount) && (
            <div className="amount-range-label">
              {method.minAmount && (
                <small>
                  {t("minAmountLabel")} {method.minAmount}
                </small>
              )}
              {method.maxAmount && (
                <small>
                  {t("maxAmountLabel")} {method.maxAmount}
                </small>
              )}
            </div>
          )}
          {!usePredefined && typeof method.predefinedAmounts !== "undefined" && (
            <div onClick={switchToPredefined}>
              <p className="button-toggle-amount-input">
                {t("SelectPredefineAmountLabel")}
              </p>
            </div>
          )}
        </div>
      )}
    </>
  );
};
