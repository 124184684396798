import React, { useContext } from 'react';
import { CheckoutContext } from '../../../context/CheckoutContext';

import success from './COMPLETED.png';
import declined from './declined.png';
import cancelled from './cancelled.png';
import inProgress from './in-progress.png';

export const StateIcon = props => {
  const { data } = useContext(CheckoutContext);

  switch (data.state) {
    case 'COMPLETED':
      return <img src={success} alt="Transaction Successful" {...props} />;
    case 'CANCELLED':
      return <img src={cancelled} alt="Transaction Cancelled" {...props} />;
    case 'DECLINED':
      return <img src={declined} alt="Transaction Declined" {...props} />;
    default:
      return <img src={inProgress} alt="In progress" {...props} />;
  }
};
