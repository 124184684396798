import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { DispatchContext } from "../../context/CheckoutContext";
import PaymentMethodLogos from "./PaymentMethodLogos";

import "./style.scss";

export const Method = ({ method }) => {
  const { setMethod } = useContext(DispatchContext);
  const { t } = useTranslation();

  return (
    <div onClick={() => setMethod(method)} className="card-method">
      <input
        className="checkbox-input-method"
        type="radio"
        name="paymentMethod"
        id={method.paymentMethod}
        value={method.paymentMethod}
      />
      <label className="checkbox-label-method" htmlFor={method.paymentMethod}>
        <img
          src={PaymentMethodLogos[method.paymentMethod.toLowerCase()]}
          alt={method.paymentMethod.toLowerCase()}
        />
        <span>{t(method.paymentMethod)}</span>
      </label>
    </div>
  );
};
