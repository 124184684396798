import React, { forwardRef, useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { DispatchContext } from '../../context/CheckoutContext';

import './style.scss';

export const Modal = forwardRef(({ setIsOpen, card }, ref) => {
  const { deleteCard } = useContext(DispatchContext);
  const { t } = useTranslation();

  const deleteAction = async card => {
    await deleteCard(card.id);
  };

  return (
    <>
      <div className="darkBG" onClick={() => setIsOpen(false)} />
      <div className="centered">
        <div className="modal">
          <div className="modalHeader">
            <h5 className="heading">{t('removeConfirmation')}</h5>
          </div>
          <div className="modalContent">{t('removeCardConfirmMessage')}</div>
          <div className="modalActions">
            <div className="actionsContainer">
              <div className="deleteBtn" onClick={() => deleteAction(card)}>
                {t('delete')}
              </div>
              <div className="cancelBtn" onClick={() => setIsOpen(false)}>
                {t('cancel')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
