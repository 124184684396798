import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CheckoutContext,
} from "../../context/CheckoutContext";
import { OrderDetails } from "../Components";
import { formatAmount } from "../../utils/CurrencyFormatter";
import QRCode from "react-qr-code";

import "./styles.scss";

export const WalletAddress = (props) => {
  const { data } = useContext(CheckoutContext);
  const { t } = useTranslation();
  const [copied, setCopied] = useState("");

  let qrCodeValue = data.externalRefs.address;
  const destinationTag = data.externalRefs.destinationTag;
  if (data.currency === "XRP" && destinationTag) {
    qrCodeValue += ("?dt=" + destinationTag)
  }

  const addressToClipboard = () => {
    navigator.clipboard.writeText(qrCodeValue);
    setCopied("copied");
  }

  return (
    <>
      <OrderDetails />
      <section className="wallet-data">
        <div className="deposit-to">{t("sendAmountInToAddress", { amount: formatAmount(data.amount, data.currency), address: data.externalRefs.address })}</div>
        {destinationTag && <div className="deposit-to"> {t("destinationTag", { destinationTag: destinationTag })} </div>}

        <div className="wallet-address-qr">
          <QRCode value={qrCodeValue} />
          <small>{t("scanAddressOr")}</small>
        </div>
        <div className={"copy-to-clipboard-button " + copied} onClick={addressToClipboard}><i className="icon-copy"></i><span>{copied === "" ? t("copyToClipboard") : t("copied")}</span></div>

        <div className="warning message_1">&#9888; {t("cryptoWarningMessage_1")}</div>
        {destinationTag && <div className="warning message_2">&#9888; {t("cryptoWarningMessage_2")}</div>}
      </section>

      {data.showBackToShopButton && (
        <a className="link-return-shop" href={data.returnUrl}>
          {t("backToShopFromInstructionPageButtonLabel")}
        </a>
      )}
    </>
  );
};
