import React, { useContext } from "react";
import { CleaveInput } from "../../../../components/input/CleaveInput";
import { CustomInput } from "../../../../components/input/CustomInput";
import { CustomPhoneInput } from "../../../../components/input/phoneInput/CustomPhoneInput";
import { CheckoutContext } from "../../../../context/CheckoutContext";
import { CountrySelect } from "../../../input/countrySelect/CountrySelect";

export const AdditionalFields = () => {
  const { method } = useContext(CheckoutContext);

  const getMaxLength = (item) => {
    switch (item) {
      case "customerFirstName":
      case "customerLastName":
        return 128
      case "customerAccountNumber":
      case "customerPersonalId":
        return 64
      case "billingAddressLine1":
      case "billingAddressLine2":
      case "billingCity":
        return 50
      case "billingState":
        return 40
      case "billingPostalCode":
        return 12
      case "customerEmail":
        return 256
      case "customerPhone":
        return 18
      default:
        return null;
    }
  }

  return (
    <div className="additional-fields">
      {method.additionalFields.map((item) => {
        switch (item) {
          case "customerCitizenshipCountryCode":
          case "billingCountryCode":
            return (
              <CountrySelect
                key={item}
                name={item}
                id={item}
                label={`${item}Label`}
                placeHolder={`${item}PlaceHolder`}
                required={item}
              />
            );
          case "customerFirstName":
          case "customerLastName":
          case "customerAccountNumber":
          case "billingAddressLine1":
          case "billingCity":
          case "billingState":
          case "billingPostalCode":
          case "customerPersonalId":
            return (
              <CustomInput
                key={item}
                name={item}
                id={item}
                label={`${item}Label`}
                placeHolder={`${item}PlaceHolder`}
                required={item}
                maxLength={getMaxLength(item)}
              />
            );
          case "customerDateOfBirth":
            return (
              <CleaveInput
                key={item}
                options={{ date: true, datePattern: ["d", "m", "Y"] }}
                name={item}
                id={item}
                label={`${item}Label`}
                placeHolder={`${item}PlaceHolder`}
                required={item}
              />
            );
          case "customerEmail":
            return (
              <CustomInput
                key={item}
                name={item}
                id={item}
                label={`${item}Label`}
                placeHolder={`${item}PlaceHolder`}
                required={item}
                maxLength={getMaxLength(item)}
                type="email"
              />
            );
          case "customerPhone":
            return (
              <CustomPhoneInput
                key={item}
                name={item}
                id={item}
                label={`${item}Label`}
                placeHolder={`${item}PlaceHolder`}
                required={item}
              />
            );
          case "billingAddressLine2":
            return (
              <CustomInput
                key={item}
                name={item}
                id={item}
                label={`${item}Label`}
                placeHolder={`${item}PlaceHolder`}
                type={item}
                maxLength={getMaxLength(item)}
              />
            );
          default:
            return null;
        }
      })}
    </div>
  );
};
