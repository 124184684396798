import React, { useState } from 'react';
import CardBrands from './CardBrands';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../../Components';

import './style.scss';

export const SavedCard = ({ card, initialSelect }) => {
  const { register } = useFormContext();

  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  let registerOptions = {};
  registerOptions.required = registerOptions.required = t(
    'selectCardValidationMessage'
  );

  return (
    <div className="card-method">
      {isOpen && <Modal setIsOpen={setIsOpen} card={card} />}
      <input
        className="checkbox-input-card"
        type="radio"
        name="savedCardId"
        id={card.id}
        value={card.id}
        ref={register(registerOptions)}
      />
      <label className="checkbox-label-card" htmlFor={card.id}>
        <img
          src={CardBrands[card.brand.toLowerCase()]}
          alt={card.brand.toLowerCase()}
        />
        <span>
          {card.panMasked.slice(0, 4)} **** {card.panMasked.slice(-4)}
        </span>

        <span className="delete-icon" onClick={() => setIsOpen(true)}></span>
      </label>
    </div>
  );
};
