import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import "./style.scss";

export const CustomSelect = (props) => {
  const { register, errors } = useFormContext();
  const { t } = useTranslation();

  const name = props.name;

  return (
    <>
      <div className="wrapper-select">
        <label htmlFor={props.id}>{t(props.name)}</label>
        <select
          ref={register({ required: `${t(props.name)} is required` })}
          id={props.id}
          name={props.name}
          className={`select-custom ${props.class}`}
        >
          <option key="placeholder" hidden value="">
            {t("customSelectPlaceHolder" + name)}
          </option>
          {props.options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.displayName}
            </option>
          ))}
        </select>
        <span className="line" />
      </div>
      {errors[name] && (
        <p className="error-validation">{errors[name].message}</p>
      )}
    </>
  );
};
