import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CheckoutContext,
  DispatchContext
} from '../../context/CheckoutContext';
import { formatAmount } from '../../utils';

import './style.scss';

export const OrderDetails = ({ hideAmount }) => {
  const { data, method } = useContext(CheckoutContext);
  const { unsetMethod, cancelPayment } = useContext(DispatchContext);
  const { t } = useTranslation();

  return (
    <section className="section-order">
      <div className="nav-grid">
        <div>
          {data.checkoutOptions && data.checkoutOptions.length > 1 && (
            <div className="button-back" onClick={() => unsetMethod()}></div>
          )}
        </div>
        {method && (
          <div className="payment-method-title">
            <span className="method-label">
              {typeof method === 'object' ? t(method.paymentMethod) : t(method)}
            </span>
          </div>
        )}

        <div>
          {data.showCancelButton && (
            <div
              className="button-cancel"
              onClick={() => cancelPayment()}
            ></div>
          )}
        </div>
      </div>
      {data.amount && !hideAmount && (
        <div>
          <p className="amount-currency">
            {data.customerAmount && t('debitAmountLabel')}
            {formatAmount(data.amount, data.currency)}
          </p>
        </div>
      )}
      {data.description && (
        <span className="description-text">{data.description}</span>
      )}
    </section>
  );
};
