import React, { useContext, useEffect } from "react";
import { CheckoutContext, DispatchContext } from "../../context/CheckoutContext";

import "./styles.scss";

export const Progress = (props) => {
  const { id } = useContext(CheckoutContext);
  const { getCheckout } = useContext(DispatchContext);

  useEffect(() => {
    const timeoutId = setTimeout(
      function () {
        if (id) {
          getCheckout(id);
          clearTimeout(timeoutId);
        }
      }, props.checkInterval);

    return (
      () => clearTimeout(timeoutId)
    );
  }, [id, props.checkInterval, getCheckout]);

  useEffect(() => {
    if (id) {
      const eventListener = new EventSource(`/sse/${id}`);

      eventListener.onmessage = () => {
        eventListener.close();
        getCheckout(id);
      };

      eventListener.onerror = () => {
        eventListener.close();
      };

      return (() => {
        eventListener.close();
      })
    }
  }, [id, getCheckout]);

  return (
    <section className="wrapper-progress">
      <div className="lds-grid"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </section>
  );
};