import React, { useState, useEffect, useContext } from "react";
import { CheckoutContext } from "../../../../context/CheckoutContext";
import { useTranslation } from "react-i18next";
import { SavedCard } from "./SavedCard";
import { CleaveInput } from "../../../input/CleaveInput";
import "./style.scss";
import { useFormContext } from "react-hook-form";

export const CardSelector = (props) => {
  const { data } = useContext(CheckoutContext);
  const { errors } = useFormContext();
  const { t } = useTranslation();
  const [savedCards, setSavedCards] = useState();

  function validateCVV(value, name) {
    let error;
    if (!value) {
      error = `${t(name)}`;
    }
    if (value.length < 3) {
      error = `${t("invalidCVV")}`;
    }

    return error || true;
  }

  useEffect(() => {
    setSavedCards(() => {
      let listItems = [];

      data.customerCards.sort(function (a, b) {
        return new Date(b.updated) - new Date(a.updated);
      });

      for (let i = 0; i < data.customerCards.length; i++) {
        listItems.push(<SavedCard key={data.customerCards[i].id} card={data.customerCards[i]} initialSelect={data.customerCards[0].id} />);
      }

      return listItems;
    });
  }, [data.customerCards]);

  return (<div className="saved-cards-wrapper">
    <div className="cards-group">{savedCards}</div>
    <div onClick={props.addNewCard}>
      <p className="add-new-card-button" >{t("AddNewCardLabel")}</p>
    </div>
    {errors["savedCardId"] && (
      <p className="error-validation">{errors["savedCardId"].message}</p>
    )}
    <CleaveInput
      options={{
        blocks: [4],
        numericOnly: true,
      }}
      name="cardSecurityCode"
      id="cvv"
      label="CardSecurityCodeLabel"
      placeHolder="CardSecurityPlaceholder"
      type="password"
      autocomplete="cc-csc"
      inputValidation={(value) => validateCVV(value, "CVVRequired")}
    />
  </div>
  );
};
