import basic_card from '../../assets/img/logos/credit-card.svg';
import skrill from '../../assets/img/logos/skrill-logo.svg';
import flexepin from '../../assets/img/logos/flexepin-logo.svg';
import neteller from '../../assets/img/logos/neteller-logo.svg';
import paypal from '../../assets/img/logos/paypal-logo.svg';
import payretailers from '../../assets/img/logos/payretailers-logo.png';
import localpayment from '../../assets/img/logos/localpayment-logo.png';
import monnet from '../../assets/img/logos/monnet-logo.png';
import trustpayments from '../../assets/img/logos/trustpayments-logo.svg';
import paymaxis from '../../assets/img/logos/paymaxis-logo.png';
import gate8transact from '../../assets/img/logos/gate8transact-logo.png';
import tink from '../../assets/img/logos/tink-logo.svg';
import b2binpay from '../../assets/img/logos/b2binpay-logo.png';
import click from '../../assets/img/logos/click-logo.png';
import monetix from '../../assets/img/logos/monetix-logo.svg';
import perfectmoney from '../../assets/img/logos/perfectmoney-logo.png';
import volt from '../../assets/img/logos/volt-logo.svg';
import kesspay from '../../assets/img/logos/kesspay-logo.png';
import crypto from '../../assets/img/logos/crypto-logo.svg';
import billline from '../../assets/img/logos/billline-logo.svg';
import ngenius from '../../assets/img/logos/ngenius-logo.svg';
import astropay from '../../assets/img/logos/astropay-logo.png';
import alycepay from '../../assets/img/logos/alycepay-logo.png';
import pix from '../../assets/img/logos/pix-logo.png';
import upi from '../../assets/img/logos/upi-logo.png';
import paytm from '../../assets/img/logos/paytm-logo.svg';
import netbanking from '../../assets/img/logos/netbanking-logo.svg';
import boleto from '../../assets/img/logos/boleto-logo.png';
import finrax from '../../assets/img/logos/finrax-logo.svg';
import spoynt from '../../assets/img/logos/spoynt-logo.svg';
import xinpay from '../../assets/img/logos/xinpay-logo.png';
import dpopay from '../../assets/img/logos/dpopay-logo.svg';
import xanpay from '../../assets/img/logos/xanpay-logo.svg';
import inrpay from '../../assets/img/logos/inrpay-logo.png';
import ari10 from '../../assets/img/logos/ari10-logo.svg';
import sofort from '../../assets/img/logos/sofort-logo.svg';
import giropay from '../../assets/img/logos/giropay-logo.svg';
import paysafecard from '../../assets/img/logos/paysafecard-logo.svg';
import open_banking from '../../assets/img/logos/open-banking-logo.png';
import spei from '../../assets/img/logos/spei.svg';
import paycash from '../../assets/img/logos/paycash-logo.svg';
import rapipago from '../../assets/img/logos/rapipago-logo.svg';
import pagofacil from '../../assets/img/logos/pagofacil-logo.svg';
import mobile_money from '../../assets/img/logos/mobile-money-logo.svg';
import interac from '../../assets/img/logos/interac-logo.svg';
import picpay from '../../assets/img/logos/picpay-logo.svg';
import mollie from '../../assets/img/logos/mollie-logo.svg';
import payid from '../../assets/img/logos/payId.svg';

export default {
  basic_card,
  skrill,
  flexepin,
  neteller,
  paypal,
  payretailers,
  localpayment,
  monnet,
  trustpayments,
  paymaxis,
  gate8transact,
  tink,
  b2binpay,
  click,
  monetix,
  perfectmoney,
  volt,
  kesspay,
  crypto,
  billline,
  ngenius,
  astropay,
  alycepay,
  pix,
  upi,
  paytm,
  netbanking,
  boleto,
  finrax,
  spoynt,
  xinpay,
  dpopay,
  xanpay,
  inrpay,
  ari10,
  sofort,
  giropay,
  paysafecard,
  open_banking,
  spei,
  paycash,
  rapipago,
  pagofacil,
  mobile_money,
  interac,
  picpay,
  mollie,
  payid
};
