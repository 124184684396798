import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CheckoutContext,
  DispatchContext
} from '../../context/CheckoutContext';
import { OrderDetails } from '../Components';
import QRCode from 'react-qr-code';

import './styles.scss';

export const PixPaymentForm = props => {
  const { id } = useContext(CheckoutContext);
  const { data } = useContext(CheckoutContext);
  const { getCheckout } = useContext(DispatchContext);
  const { t } = useTranslation();
  const [copied, setCopied] = useState('');

  let qrCodeValue = data.externalRefs.qrCode;

  const addressToClipboard = () => {
    navigator.clipboard.writeText(qrCodeValue);
    setCopied('copied');
  };

  useEffect(() => {
    const timeoutId = setTimeout(function () {
      if (id) {
        getCheckout(id);
        clearTimeout(timeoutId);
      }
    }, props.checkInterval);

    return () => clearTimeout(timeoutId);
  }, [id, props.checkInterval, getCheckout]);

  useEffect(() => {
    if (id) {
      const eventListener = new EventSource(`/sse/${id}`);

      eventListener.onmessage = () => {
        eventListener.close();
        getCheckout(id);
      };

      eventListener.onerror = () => {
        eventListener.close();
      };

      return () => {
        eventListener.close();
      };
    }
  }, [id, getCheckout]);

  return (
    <>
      <OrderDetails />

      <section className="wallet-data">
        <div className="wallet-address-qr">
          <QRCode value={qrCodeValue} />
          <small>{t('scanAddressOr')}</small>
        </div>

        <div
          className={'copy-to-clipboard-button ' + copied}
          onClick={addressToClipboard}
        >
          <i className="icon-copy"></i>
          <span>{copied === '' ? t('copyToClipboard') : t('copied')}</span>
        </div>
      </section>

      {data.showBackToShopButton && (
        <a className="link-return-shop" href={data.returnUrl}>
          {t('backToShopFromInstructionPageButtonLabel')}
        </a>
      )}
    </>
  );
};
