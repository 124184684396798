import generic from "../../../../assets/card-brands/generic-mono.svg";
import visa from "../../../../assets/card-brands/visa.svg";
import alipay from "../../../../assets/card-brands/alipay.svg";
import amex from "../../../../assets/card-brands/amex.svg";
import diners from "../../../../assets/card-brands/diners.svg";
import discover from "../../../../assets/card-brands/discover.svg";
import elo from "../../../../assets/card-brands/elo.svg";
import hipercard from "../../../../assets/card-brands/hipercard.svg";
import jcb from "../../../../assets/card-brands/jcb.svg";
import maestro from "../../../../assets/card-brands/maestro.svg";
import mastercard from "../../../../assets/card-brands/mastercard.svg";
import paypal from "../../../../assets/card-brands/paypal.svg";
import unionpay from "../../../../assets/card-brands/unionpay.svg";

export default {generic, visa, alipay, amex, diners, discover, elo, hipercard, jcb, maestro, mastercard, paypal, unionpay}

