import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { OrderDetails } from "../Components";
import { useForm, FormProvider } from "react-hook-form";
import { CustomInput } from "../input/CustomInput";
import { DispatchContext } from "../../context/CheckoutContext";

import "./styles.scss";

export const UpiSelectModeForm = ({ htmlElements }) => {
  const { t } = useTranslation();
  const { postCustomForm } = useContext(DispatchContext);
  const showUpiVpa = htmlElements ? htmlElements.findIndex(e => e.name === "upiVpa") > -1 : true;
  const showRequestQrButton = htmlElements ? htmlElements.findIndex(e => e.name === "requestQr") > -1 : true;

  const requestQrForm = useForm({
    reValidateMode: "onBlur",
    mode: "onBlur",
    shouldFocusError: false,
  });

  const submitVpaForm = useForm({
    reValidateMode: "onBlur",
    mode: "onBlur",
    shouldFocusError: false,
  });

  const onSubmitCustomForm = (form) => {
    postCustomForm(form);
  };

  return (
    <>
      <OrderDetails />

      {showRequestQrButton && (
        <FormProvider {...requestQrForm}>
          <form onSubmit={requestQrForm.handleSubmit(onSubmitCustomForm)}>
            <div className="form-card upi-form">
              <CustomInput key="upiMode" id="upiMode" name="upiMode" type="hidden" additionalAttributes={{ value: "qrCode" }} />
              <input className="button-submit upi-button" type="submit" value={t("upiRequestQrButton")} />
            </div>
          </form>
        </FormProvider>
      )}

      {showUpiVpa && (
        <FormProvider {...submitVpaForm}>
          <form onSubmit={submitVpaForm.handleSubmit(onSubmitCustomForm)}>
            <div className="form-card upi-form">
              <CustomInput key="upiMode" id="upiMode" name="upiMode" type="hidden" additionalAttributes={{ value: "collect" }} />
              <CustomInput key="upiVpa" id="upiVpa" name="upiVpa" label="upiVpaLabel" placeHolder="upiVpaPlaceholder" pattern="^[a-zA-Z0-9.\-_]{2,49}@[a-zA-Z._]{2,49}$" required={true} />
              <span className="upi-vpa-hint">{t("upiVpaHint")}</span>
              <input className="button-submit upi-button" type="submit" value={t("upiSubmitVpaButton")} />
            </div>
          </form>
        </FormProvider>
      )}
    </>
  );
};
