import React, { useState, useContext } from "react";
import { FormProvider } from "react-hook-form";
import { CheckoutContext } from "../../context/CheckoutContext";

import { CardForm, OrderDetails, AlternativeForm } from "../Components";

import "./styles.scss";

export const PaymentForm = ({ form, onSubmit }) => {
  const { data, method } = useContext(CheckoutContext);

  const [requestAmount] = useState(!data.amount);

  function showMethodForm() {
    switch (method.paymentMethod) {
      case "BASIC_CARD":
        return <CardForm requestAmount={requestAmount} />;
      default:
        return <AlternativeForm requestAmount={requestAmount} />;
    }
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit}>
        <OrderDetails />
        {showMethodForm()}
      </form>
      {method.footerText && <div className="form-footer" dangerouslySetInnerHTML={{ __html: method.footerText }} />}
    </FormProvider>
  );
};
