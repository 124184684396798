import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import "cleave.js/dist/addons/cleave-phone.i18n.js";
import Cleave from "cleave.js/react";
import { useTranslation } from "react-i18next";
import getSymbolFromCurrency from "currency-symbol-map";

export const CleaveInput = (props) => {
  const { control, errors } = useFormContext();

  const { t } = useTranslation();

  const name = props.name;

  return (
    <div className="input-row">
      <div className="wrapper-input">
        <label htmlFor={props.id}>{t(props.label)}</label>
        <Controller
          as={
            <Cleave
              options={props.options}
              id={props.id}
              name={props.name}
              placeholder={t(props.placeHolder)}
              type={props.type}
              className={`input-custom ${props.class}`}
              autoComplete={props.autocomplete}
            />
          }
          name={props.name}
          control={control}
          defaultValue=""
          rules={{ validate: props.inputValidation }}
        />
        {props.img != null && props.img !== "unknown" ? (
          <img className="cardBrand" alt="Card brand" src={props.img} />
        ) : null}
        {props.currency && (
          <span className="amount-input-currency">
            {getSymbolFromCurrency(props.currency)}&nbsp; ({props.currency})
          </span>
        )}
        <span className="line" />
      </div>
      {errors[name] && (
        <p className="error-validation">{errors[name].message}</p>
      )}
    </div>
  );
};
