import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import "./main.scss";

export const CustomInput = (props) => {
  const { register, errors } = useFormContext();
  const { t } = useTranslation();

  const name = props.name;

  let registerOptions = {}
  if (props.required) {
    registerOptions.required = t("validationRequired", { fieldName: t(props.label) });
  }
  if (props.maxLength) {
    registerOptions.maxLength = {
      value: props.maxLength,
      message: t("validationMaxLength", { maxLength: props.maxLength })
    }
  }
  if (props.pattern) {
    registerOptions.pattern = {
      value: new RegExp(props.pattern),
      message: t("validationPattern")
    }
  }

  return (
    <div className="input-row">
      <div className="wrapper-input">
        <label htmlFor={props.id}>{t(props.label)}</label>
        <input
          ref={register(registerOptions)}
          id={props.id}
          name={props.name}
          placeholder={t(props.placeHolder)}
          type={props.type}
          className={`input-custom ${props.class}`}
          {...props.additionalAttributes}
        />
        <span className="line" />
      </div>
      {errors[name] && (
        <p className="error-validation">{errors[name].message}</p>
      )}
    </div>
  );
};
