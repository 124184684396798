export const supportedCurrencies = {
  AED: { exponent: 2, symbol: 'DH' },
  AFN: { exponent: 2 },
  ALL: { exponent: 2 },
  AMD: { exponent: 2 },
  ANG: { exponent: 2 },
  AOA: { exponent: 2 },
  ARS: { exponent: 2 },
  AUD: { exponent: 2, symbol: 'A$' },
  AWG: { exponent: 2 },
  AZN: { exponent: 2 },
  BAM: { exponent: 2 },
  BBD: { exponent: 2 },
  BCH: { exponent: 8 },
  BDT: { exponent: 2 },
  BGN: { exponent: 2 },
  BHD: { exponent: 3 },
  BIF: { exponent: 0 },
  BMD: { exponent: 2 },
  BND: { exponent: 2 },
  BOB: { exponent: 2 },
  BOV: { exponent: 2 },
  BRL: { exponent: 2, symbol: 'R$' },
  BSD: { exponent: 2 },
  BTC: { exponent: 8, symbol: '₿' },
  BTN: { exponent: 2 },
  BWP: { exponent: 2 },
  BYN: { exponent: 2 },
  BYR: { exponent: 0 },
  BZD: { exponent: 2 },
  CAD: { exponent: 2, symbol: 'C$' },
  CDF: { exponent: 2 },
  CHE: { exponent: 2 },
  CHF: { exponent: 2, symbol: 'Fr.' },
  CHW: { exponent: 2 },
  CLF: { exponent: 4 },
  CLP: { exponent: 0 },
  CNY: { exponent: 2 },
  COP: { exponent: 2 },
  COU: { exponent: 2 },
  CRC: { exponent: 2 },
  CUC: { exponent: 2 },
  CUP: { exponent: 2 },
  CVE: { exponent: 2 },
  CZK: { exponent: 2, symbol: 'Kč' },
  DASH: { exponent: 8 },
  DJF: { exponent: 0 },
  DKK: { exponent: 2, symbol: 'kr.' },
  DOGE: { exponent: 8 },
  DOP: { exponent: 2 },
  DZD: { exponent: 2 },
  EGP: { exponent: 2 },
  ERN: { exponent: 2 },
  ETB: { exponent: 2 },
  ETH: { exponent: 18, symbol: 'Ξ' },
  EUR: { exponent: 2, symbol: '€' },
  FJD: { exponent: 2 },
  FKP: { exponent: 2 },
  GBP: { exponent: 2, symbol: '£' },
  GEL: { exponent: 2, symbol: '₾' },
  GHS: { exponent: 2 },
  GIP: { exponent: 2 },
  GMD: { exponent: 2 },
  GNF: { exponent: 0 },
  GTQ: { exponent: 2 },
  GYD: { exponent: 2 },
  HKD: { exponent: 2, symbol: 'HK$' },
  HNL: { exponent: 2 },
  HRK: { exponent: 2 },
  HTG: { exponent: 2 },
  HUF: { exponent: 2, symbol: 'Ft' },
  IDR: { exponent: 2, symbol: 'Rp' },
  ILS: { exponent: 2, symbol: '₪' },
  INR: { exponent: 2, symbol: '₹' },
  IQD: { exponent: 3 },
  IRR: { exponent: 2 },
  ISK: { exponent: 0 },
  JMD: { exponent: 2 },
  JOD: { exponent: 3 },
  JPY: { exponent: 0, symbol: '¥' },
  KES: { exponent: 2 },
  KGS: { exponent: 2 },
  KHR: { exponent: 2 },
  KMF: { exponent: 0 },
  KPW: { exponent: 2 },
  KRW: { exponent: 0, symbol: '₩' },
  KWD: { exponent: 3, symbol: 'KD' },
  KYD: { exponent: 2 },
  KZT: { exponent: 2 },
  LAK: { exponent: 2 },
  LBP: { exponent: 2 },
  LKR: { exponent: 2, symbol: 'Rs' },
  LRD: { exponent: 2 },
  LSL: { exponent: 2 },
  LTC: { exponent: 8 },
  LYD: { exponent: 3 },
  MAD: { exponent: 2 },
  MDL: { exponent: 2 },
  MGA: { exponent: 2 },
  MKD: { exponent: 2 },
  MMK: { exponent: 2 },
  MNT: { exponent: 2 },
  MOP: { exponent: 2 },
  MRO: { exponent: 2 },
  MUR: { exponent: 2 },
  MVR: { exponent: 2 },
  MWK: { exponent: 2 },
  MXN: { exponent: 2, symbol: 'MX$' },
  MXV: { exponent: 2 },
  MYR: { exponent: 2, symbol: 'RM' },
  MZN: { exponent: 2 },
  NAD: { exponent: 2 },
  NGN: { exponent: 2 },
  NIO: { exponent: 2 },
  NOK: { exponent: 2, symbol: 'kr' },
  NPR: { exponent: 2 },
  NZD: { exponent: 2, symbol: 'NZ$' },
  OMR: { exponent: 3 },
  PAB: { exponent: 2 },
  PEN: { exponent: 2 },
  PGK: { exponent: 2 },
  PHP: { exponent: 2, symbol: '₱' },
  PKR: { exponent: 2, symbol: '₨' },
  PLN: { exponent: 2, symbol: 'zł' },
  PYG: { exponent: 0 },
  QAR: { exponent: 2 },
  RON: { exponent: 2 },
  RSD: { exponent: 2 },
  RUB: { exponent: 2, symbol: '₽' },
  RWF: { exponent: 0 },
  SAR: { exponent: 2, symbol: 'SR' },
  SBD: { exponent: 2 },
  SCR: { exponent: 2 },
  SDG: { exponent: 2 },
  SEK: { exponent: 2, symbol: 'kr' },
  SGD: { exponent: 2, symbol: 'S$' },
  SHP: { exponent: 2 },
  SLL: { exponent: 2 },
  SOS: { exponent: 2 },
  SRD: { exponent: 2 },
  SSP: { exponent: 2 },
  STD: { exponent: 2 },
  SVC: { exponent: 2 },
  SYP: { exponent: 2 },
  SZL: { exponent: 2 },
  THB: { exponent: 2, symbol: '฿' },
  TJS: { exponent: 2 },
  TMT: { exponent: 2 },
  TND: { exponent: 3 },
  TOP: { exponent: 2 },
  TRX: { exponent: 8 },
  TRY: { exponent: 2, symbol: '₺' },
  TTD: { exponent: 2 },
  TWD: { exponent: 2, symbol: 'NT$' },
  TZS: { exponent: 2 },
  UAH: { exponent: 2 },
  UGX: { exponent: 0 },
  USD: { exponent: 2, symbol: '$' },
  USDT: { exponent: 8 },
  USN: { exponent: 2 },
  UYI: { exponent: 0 },
  UYU: { exponent: 2 },
  UZS: { exponent: 2 },
  VEF: { exponent: 2 },
  VND: { exponent: 0 },
  VUV: { exponent: 0 },
  WST: { exponent: 2 },
  XAF: { exponent: 0 },
  XCD: { exponent: 2 },
  XMR: { exponent: 12 },
  XOF: { exponent: 0 },
  XPF: { exponent: 0 },
  XRP: { exponent: 6 },
  YER: { exponent: 2 },
  ZAR: { exponent: 2, symbol: 'R' },
  ZEC: { exponent: 8 },
  ZMW: { exponent: 2 },
  ZWL: { exponent: 2 }
};

export const formatAmount = (amount, currencyCode) => {
  if (!amount) return '';

  const currency = supportedCurrencies[currencyCode];
  if (!currency) console.error('Currency not supported', currency);

  const exponent = currency ? currency.exponent : 2;
  const symbol =
    currency && currency.symbol ? currency.symbol : currencyCode + ' ';
  const language = navigator.language || navigator.userLanguage;

  return (
    symbol +
    Number(amount.toFixed(exponent)).toLocaleString(language, {
      maximumFractionDigits: exponent
    })
  );
};
