import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { OrderDetails } from "../Components";
import { FormProvider } from "react-hook-form";
import { CustomSelect } from "../input/customSelect/CustomSelect";
import { CustomInput } from "../input/CustomInput";
import { CheckoutContext } from "../../context/CheckoutContext";

//import "./styles.scss";

export const CustomForm = ({ form, onSubmit }) => {
  const { data } = useContext(CheckoutContext);

  const { t } = useTranslation();
  const renderCustomForm = () => {
    let elementsList = [];
    data.htmlElements.forEach((element) => {
      let newElement;
      switch (element.tag) {
        case "select":
          newElement = (
            <CustomSelect
              key={element.name}
              options={element.options}
              id={element.name}
              name={element.name}
              required={true}
            />
          );
          elementsList.push(newElement);
          break;
        case "input":
          newElement = (
            <CustomInput
              key={element.name}
              options={element.options}
              id={element.name}
              name={element.name}
              label={element.name}
              placeholder={element.name}
              additionalAttributes={element.attributes}
              required={true}
            />
          );
          elementsList.push(newElement);
          break;
        default:
          break;
      }
    });
    return elementsList;
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit}>
        <OrderDetails />
        <div className="form-card">
          {renderCustomForm()}
          <input
            className="button-submit"
            type="submit"
            value={t("ButtonSubmitLabel")}
          />
        </div>
      </form>
    </FormProvider>
  );
};
