import React, { useState, useEffect, useContext } from 'react';
import {
  PaymentForm,
  Progress,
  State,
  Cascading,
  MethodGroup,
  Confirmation,
  CustomForm,
  WalletAddress,
  PixPaymentForm,
  UpiSelectModeForm,
  UpiQrCodeForm,
  SpeiPaymentForm,
  PayCashPaymentForm,
  PayID
} from './components/Components';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  buildRequest,
  buildBrowserInfoRequest,
  handleRedirection
} from './utils';

import './App.scss';
import { CheckoutContext, DispatchContext } from './context/CheckoutContext';

const supportedLocales = ['en', 'ru', 'it', 'fr'];
const imgLogoSrc = '/branding/logo.svg';

function App() {
  const form = useForm({
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    shouldFocusError: false
  });
  const { id, isLoading, data, method } = useContext(CheckoutContext);
  const { startLoading, postCheckout, postCustomForm, setMethod } =
    useContext(DispatchContext);
  const { i18n } = useTranslation();
  const [progressTimeout, setProgressTimeout] = useState(false);
  const [checkInterval, setCheckInterval] = useState(2000);

  useEffect(() => {
    if (!data) {
      startLoading();
      return;
    }

    if (supportedLocales.indexOf(data.locale) >= 0) {
      i18n.changeLanguage(data.locale);
    }

    switch (data.state) {
      case 'AWAITING_REDIRECT':
        startLoading();
        handleRedirection(data.redirectUrl, id);
        break;
      case 'CHECKOUT':
        if (shouldAutoPostCheckout()) {
          const request = buildBrowserInfoRequest();

          if (data.checkoutOptions[0].paymentMethod !== 'BASIC_CARD') {
            postCheckout({
              paymentMethod: data.checkoutOptions[0].paymentMethod,
              ...request
            });
            break;
          }
          postCheckout(request);
          break;
        }
        if (data.checkoutOptions.length === 1) {
          setMethod(data.checkoutOptions[0]);
        }
        break;
      case 'COMPLETED':
      case 'DECLINED':
      case 'CANCELLED':
        if (data.returnUrl !== null && !data.showResultPage) {
          window.location.replace(data.returnUrl);
        }
        break;
      case 'INPUT_REQUIRED':
      case 'AWAITING_CONFIRMATION':
      case 'CASCADING_CONFIRMATION':
        break;
      default:
        setCheckInterval(i =>
          i < 60000 ? i * 2 : i === 64000 ? 60000 : 64000
        );
        startLoading();
        if (data.returnInProgressTimeout) {
          setTimeout(function () {
            if (data.showResultPage) {
              setProgressTimeout(true);
            } else {
              window.location.replace(data.returnUrl);
            }
          }, data.returnInProgressTimeout * 1000);
        }
    }
    // intentionally disabled eslint
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const shouldAutoPostCheckout = () => {
    return (
      data.amount &&
      data.checkoutOptions.length === 1 &&
      !data.checkoutOptions[0].additionalFields &&
      (data.checkoutOptions[0].paymentMethod !== 'BASIC_CARD' ||
        data.cardDetails)
    );
  };

  const showPaymentInstructions = () => {
    return (
      data &&
      data.externalRefs &&
      (data.state === 'AWAITING_WEBHOOK' || data.state === 'RECONCILIATION') &&
      ((data.paymentMethod === 'CRYPTO' && data.externalRefs.address) ||
        (data.paymentMethod === 'PIX' && data.externalRefs.qrCode) ||
        (data.paymentMethod === 'UPI' &&
          (data.externalRefs.qrCode || data.externalRefs.upiVpa)) ||
        data.paymentMethod === 'SPEI' ||
        data.paymentMethod === 'PAYCASH' ||
        (data.paymentMethod === 'PAYID' && data.externalRefs.payId))
    );
  };

  const onSubmit = form => {
    const request = buildRequest(form, method, data.amount);
    postCheckout(request);
  };

  const onSubmitCustomForm = form => {
    postCustomForm(form);
  };

  function show() {
    if (data) {
      if (progressTimeout) {
        return <State />;
      }
      if (showPaymentInstructions()) {
        if (data.paymentMethod === 'PIX') {
          return <PixPaymentForm checkInterval={checkInterval} />;
        }
        if (data.paymentMethod === 'UPI') {
          return <UpiQrCodeForm checkInterval={checkInterval} />;
        }
        if (data.paymentMethod === 'SPEI') {
          return <SpeiPaymentForm checkInterval={checkInterval} />;
        }
        if (data.paymentMethod === 'PAYCASH') {
          return <PayCashPaymentForm checkInterval={checkInterval} />;
        }
        if (data.paymentMethod === 'PAYID') {
          return <PayID checkInterval={checkInterval} />;
        }
        return <WalletAddress />;
      }

      switch (data.state) {
        case 'CHECKOUT':
          if (method != null && data.checkoutOptions.length > 0) {
            return (
              <PaymentForm form={form} onSubmit={form.handleSubmit(onSubmit)} />
            );
          }
          return <MethodGroup />;
        case 'COMPLETED':
        case 'DECLINED':
        case 'CANCELLED':
        case 'ERROR':
          return <State />;
        case 'AWAITING_CONFIRMATION':
          return <Confirmation />;
        case 'CASCADING_CONFIRMATION':
          return <Cascading />;
        case 'INPUT_REQUIRED':
          if (data.paymentMethod === 'UPI') {
            return <UpiSelectModeForm htmlElements={data.htmlElements} />;
          }
          return (
            <CustomForm
              form={form}
              onSubmit={form.handleSubmit(onSubmitCustomForm)}
            />
          );
        default:
          return;
      }
    }
  }

  return (
    <>
      {data && data.cssUrl && (
        <link rel="stylesheet" type="text/css" href={data.cssUrl} />
      )}
      {isLoading && !showPaymentInstructions() ? (
        <Progress checkInterval={checkInterval} />
      ) : (
        <div className="animated-form">
          <main className="container overflow-hidden">
            {data && data.showLogo ? (
              <img className="logo" src={imgLogoSrc} alt="Brand logo" />
            ) : (
              <div className="logo"></div>
            )}
            {show()}
          </main>
        </div>
      )}
    </>
  );
}

export default App;
