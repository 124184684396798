import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CheckoutContext, DispatchContext } from "../../context/CheckoutContext";
import { OrderDetails } from "../Components";

import "./styles.scss";

export const UpiQrCodeForm = (props) => {
  const { id } = useContext(CheckoutContext);
  const { data } = useContext(CheckoutContext);
  const { getCheckout } = useContext(DispatchContext);
  const { t } = useTranslation();

  const qrCodeValue = data.externalRefs.qrCode;
  const upiVpaValue = data.externalRefs.upiVpa;

  useEffect(() => {
    const timeoutId = setTimeout(
      function () {
        if (id) {
          getCheckout(id);
          clearTimeout(timeoutId);
        }
      }, props.checkInterval);

    return (
      () => clearTimeout(timeoutId)
    );
  }, [id, props.checkInterval, getCheckout]);

  useEffect(() => {
    if (id) {
      const eventListener = new EventSource(`/sse/${id}`);

      eventListener.onmessage = () => {
        eventListener.close();
        getCheckout(id);
      };

      eventListener.onerror = () => {
        eventListener.close();
      };

      return (() => {
        eventListener.close();
      })
    }
  }, [id, getCheckout]);

  return (
    <>
      <OrderDetails />

      {qrCodeValue &&
        <section className="upi-qr-code-data">
          <img src={qrCodeValue} alt="QR code" />
          <span>{t("upiQrCodeHint")}</span>
        </section>
      }

      {upiVpaValue &&
        <section className="upi-vpa-data">
          <span className="upi-vpa-value">{upiVpaValue}</span>
          <span>{t("upiVpaPaymentInstruction")}</span>
        </section>
      }

      {data.showBackToShopButton && (<a className="link-return-shop upi-button" href={data.returnUrl}>{t("backToShopFromInstructionPageButtonLabel")}</a>)}
    </>
  );
};
