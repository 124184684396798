import React, { useReducer, createContext, useEffect, useCallback } from "react";

import checkoutReducer from "./checkout-reducer.js";
export const CheckoutContext = createContext();
export const DispatchContext = createContext();

const initialState = {
  isLoading: true,
  method: null,
  token: null,
  id: null,
  data: null,
  error: null,
};

const CheckoutProvider = ({ children }) => {
  const [state, dispatch] = useReducer(checkoutReducer, initialState);

  useEffect(() => {
    const regexMatch = window.location.href.match(/payment\/([0-9a-z]{32})/);
    const url = new URL(window.location.href);
    const urlParams = new URLSearchParams(url.search);
    if (regexMatch) {
      const idFromUrl = regexMatch[1];
      dispatch({
        type: "SET_ID",
        payload: idFromUrl,
      });
    } else if (urlParams.has("token")) {
      dispatch({
        type: "SET_TOKEN",
        payload: urlParams.get("token"),
      });
    }
  }, []);

  const getCheckout = useCallback(async (id, token) => {
    dispatch({
      type: "LOADING",
    });

    const url = id ? `/checkouts/${id}` : `/checkouts?token=${token}`;

    await fetch(url)
      .then((response) => response.json())
      .then((response) => {
        if (['COMPLETED', 'DECLINED', 'CANCELLED'].includes(response.state) && response.returnUrl !== null && !response.showResultPage) {
          window.location.replace(response.returnUrl);
          return;
        }

        dispatch({
          type: "GET_SUCCESS",
          payload: response,
        });
      })
      .catch((error) => {
        console.error(error);
        dispatch({
          type: "GET_FAIL",
        });
      });
  }, []);

  useEffect(() => {
    if (!state.data && (state.id || state.token)) {
      getCheckout(state.id, state.token);
    }
  }, [state.data, state.id, state.token, getCheckout]);

  const postCheckout = async (body) => {
    dispatch({
      type: "LOADING",
    });

    let url = state.id
      ? `/checkouts/${state.id}/form`
      : `/checkouts?token=${state.token}`;

    let options = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: !body ? JSON.stringify({}) : JSON.stringify(body),
    };

    await fetch(url, options)
      .then((response) => response.text())
      .then((response) => {
        return response ? JSON.parse(response) : null;
      })
      .then((data) => {
        if (data && data.id) {
          dispatch({
            type: "POST_SUCCESS",
            payload: data.id,
          });
          if (state.token) {
            startProcess(data.id);
          }
        }
      })
      .catch((error) => {
        dispatch({
          type: "POST_FAIL",
        });
      });
  };

  const postCustomForm = async (body) => {
    dispatch({
      type: "LOADING",
    });

    let url = `/checkouts/${state.id}/customForm`;

    var formBody = [];
    for (var property in body) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(body[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    let options = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      method: "POST",
      body: formBody,
    };

    await fetch(url, options)
      .then((response) => response.text())
      .then((response) => {
        return response ? JSON.parse(response) : null;
      })
      .then((data) => {
        if (data && data.id) {
          dispatch({
            type: "POST_SUCCESS",
            payload: data.id,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: "POST_FAIL",
        });
      });
  };

  const confirmRequest = async () => {
    dispatch({
      type: "CONFIRM_REQUEST",
    });

    let options = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
    };

    await fetch(`/checkouts/${state.id}/confirm`, options).then((response) => {
      if (!response.ok) {
        throw new Error(response);
      }
    });
  };

  const confirmCascading = async () => {
    dispatch({
      type: "CONFIRM_CASCADING",
    });

    let options = {
      headers: {
        Accept: "application/json"
      },
      method: "POST",
    };

    await fetch(`/checkouts/${state.id}/confirm-cascading`, options).then((response) => {
      if (!response.ok) {
        throw new Error(response);
      }
    });
  };

  const cancelPayment = async () => {
    dispatch({
      type: "CANCEL_REQUEST",
    });

    let options = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
    };

    await fetch(`/checkouts/${state.id}/cancel`, options).then((response) => {
      if (!response.ok) {
        throw new Error(response);
      }
    });
  };

  const startProcess = async (id) => {
    await fetch(`/checkouts/${id}/process`, { method: "POST" }).then((response) => {
      if (!response.ok) {
        throw new Error(response);
      }
    });
  };

  const startLoading = () => {
    dispatch({
      type: "LOADING",
    });
  };

  const setMethod = (method) => {
    dispatch({
      type: "SET_METHOD",
      payload: method,
    });
  };

  const unsetMethod = () => {
    dispatch({
      type: "UNSET_METHOD",
    });
  };

  const deleteCard = async (cardId) => {
    let filter = state.data.customerCards.filter(card => {
      return card.id !== cardId;
    });

    // dispatch({
    //   type: "DELETE_CARD",
    //   payload: filter,
    // });

    await fetch(`/checkouts/customer-cards/${cardId}`, { method: "DELETE" }).then(
      (response) => {
        if (response.ok) {
          dispatch({
            type: "DELETE_CARD",
            payload: filter,
          });
        } else {
          //throw new Error(response.status);
        }
      }
    );
  }

  return (
    <CheckoutContext.Provider value={state}>
      <DispatchContext.Provider
        value={{
          cancelPayment,
          confirmCascading,
          confirmRequest,
          postCustomForm,
          getCheckout,
          postCheckout,
          startLoading,
          setMethod,
          unsetMethod,
          deleteCard,
        }}
      >
        {children}
      </DispatchContext.Provider>
    </CheckoutContext.Provider>
  );
};

export default CheckoutProvider;