import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { formatAmount } from '../../utils/CurrencyFormatter';

import './styles.scss';
import { CheckoutContext } from '../../context/CheckoutContext';
import { StateIcon } from './res/StateIcon';

export const State = () => {
  const { data } = useContext(CheckoutContext);
  const { t } = useTranslation();

  return (
    <div className="wrapper-state">
      {data.amount && (
        <div>
          <p className="amount-currency">
            {formatAmount(data.amount, data.currency)}
          </p>
        </div>
      )}
      {data.description && (
        <span className="description-text">{data.description}</span>
      )}
      <h1 className={`title-state ${data.state}`}>{t(data.state)}</h1>
      <div className="status-animation-wrapper">
        <StateIcon className="state-icon" />
      </div>
      {data.showBackToShopButton && (
        <a className="link-return-shop" href={data.returnUrl}>
          {t('backToShopButtonLabel')}
        </a>
      )}
    </div>
  );
};
