import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { useTranslation } from 'react-i18next';

import 'react-phone-number-input/style.css';
import './style.scss';

export const CustomPhoneInput = props => {
  const { control, errors } = useFormContext();
  const { t } = useTranslation();
  const name = props.name;

  function validatePhoneNumber(value) {
    let error;
    if (!value) {
      error = `${t('PhoneNumberRequired')}`;
    } else {
      error = isPhoneNumber(value);
    }

    return error || true;
  }

  function isPhoneNumber(value) {
    if (!isValidPhoneNumber(value)) return `${t('PhoneNumberInvalid')}`;
  }

  return (
    <div className="input-row">
      <div className="wrapper-input">
        <label htmlFor={props.id}>{t(props.label)}</label>
        <Controller
          as={
            <PhoneInput
              international
              flagUrl="assets/country-flags/{XX}.svg"
              id={props.id}
              className={`input-custom ${props.class}`}
              placeholder={t('customerPhonePlaceHolder')}
            />
          }
          name={props.name}
          control={control}
          defaultValue=""
          rules={{ validate: value => validatePhoneNumber(value) }}
        />
        <span className="line" />
      </div>
      {errors[name] && (
        <p className="error-validation">{errors[name].message}</p>
      )}
    </div>
  );
};
