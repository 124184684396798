import React, { useState, useEffect, useContext } from 'react';
import { CustomInput } from '../../../input/CustomInput';
import { CleaveInput } from '../../../input/CleaveInput';
import { AdditionalFields } from '../../../Components';
import { Amount } from '../amount/Amount';
import { useTranslation } from 'react-i18next';
import CardBrands from './CardBrands';
import { useFormContext } from 'react-hook-form';

import './style.scss';
import { ReactComponent as SecureLogos } from '../../../../assets/img/pci-master-visa.svg';
import { CheckoutContext } from '../../../../context/CheckoutContext';
import { CardSelector } from './CardSelector';

export const CardForm = ({ requestAmount }) => {
  const { data, method } = useContext(CheckoutContext);
  const [brand, setBrand] = useState(null);
  const [type, setType] = useState(null);
  const [saveCard] = useState(false);
  const { t } = useTranslation();
  const { register } = useFormContext();

  const [hasSavedCards, setHasSavedCards] = useState(
    data.hasOwnProperty('customerCards') && data.customerCards.length > 0
  );
  const [addNewCard, setAddNewCard] = useState(false);

  function onCreditCardTypeChanged(type) {
    setType(type);
  }

  function validatePan(value) {
    let error;
    if (!value) {
      error = `${t('CardNumberRequired')}`;
    } else {
      error = isCardValid(value);
    }

    return error || true;
  }

  function validateCVV(value, name) {
    let error;
    if (!value) {
      error = `${t(name)}`;
    }
    if (value.length < 3) {
      error = `${t('invalidCVV')}`;
    }

    return error || true;
  }

  function validateExpiryDate(value) {
    let error;
    if (!value) {
      error = `${t('CardExpiryDateRequired')}`;
    }
    if (value.length < 5 || !isValidDate(value)) {
      error = `${t('CardExpiryDateInvalid')}`;
    }

    return error || true;
  }

  function isValidDate(date) {
    let today = new Date().toISOString().slice(0, 10);
    let todaySplit = today.split('-');
    let currentYear = todaySplit[0].substring(2, 4);
    let currentMonth = todaySplit[1];

    let dateSplit = date.split('/');
    let dateYear = dateSplit[1];
    let dateMonth = dateSplit[0];

    if (dateYear < currentYear) {
      return false;
    } else if (dateMonth < currentMonth && dateYear === currentYear) {
      return false;
    } else {
      return true;
    }
  }

  function isCardValid(value) {
    if (/[^0-9-\s]+/.test(value)) return false;
    let check = 0;
    let even = false;
    value = value.replace(/\D/g, '');

    if (value.length < 12 || value.length > 23) {
      return `${t('CardNumberInvalid')}`;
    }

    for (let n = value.length - 1; n >= 0; n--) {
      let digitAtN = value.charAt(n);
      let digitAsInt = parseInt(digitAtN, 10);

      if (even) {
        if ((digitAsInt *= 2) > 9) digitAsInt -= 9;
      }
      check += digitAsInt;
      even = !even;
    }

    if (check % 10 !== 0) {
      return `${t('CardNumberInvalid')}`;
    }

    return true;
  }

  function removeCard(id) {
    const index = data.customerCards
      .map(function (element) {
        return element.id;
      })
      .indexOf(id);

    data.customerCards.splice(index, 1);
  }

  useEffect(() => {
    setBrand(CardBrands[type]);
  }, [type]);

  useEffect(() => {
    setHasSavedCards(
      data.hasOwnProperty('customerCards') && data.customerCards.length > 0
    );
  }, [data, data.customerCards]);

  return (
    <div className="form-card">
      {requestAmount && <Amount />}

      {data.cardDetails && data.cardDetails.maskedCardNumber ? (
        <div className="card-from-request-wrapper">
          <span>{t('maskedPanMessage')}</span>
          <span>
            {data.cardDetails.brand} {t('endingIn')}{' '}
            {data.cardDetails.maskedCardNumber.slice(
              data.cardDetails.maskedCardNumber.length - 4
            )}
          </span>
        </div>
      ) : hasSavedCards && !addNewCard ? (
        <div>
          <CardSelector
            key="savedCustomerCard"
            options={data.customerCards}
            id="customerCardId"
            name="customerCardId"
            addNewCard={() => setAddNewCard(true)}
            removeCard={() => removeCard}
          />
        </div>
      ) : (
        <>
          <CleaveInput
            options={{
              creditCard: true,
              creditCardStrictMode: true,
              onCreditCardTypeChanged
            }}
            name="cardNumber"
            id="number"
            label="cardPanLabel"
            placeHolder="cardPanPlaceHolder"
            class="input-card"
            autocomplete="cc-number"
            img={brand}
            inputValidation={value => validatePan(value)}
          />
          <CustomInput
            name="cardholderName"
            id="holder"
            label="cardHolderLabel"
            placeHolder="cardHolderPlaceHolder"
            required="Holder name"
            autocomplete="cc-name"
          />
          <div className="row-two">
            <CleaveInput
              options={{
                date: true,
                datePattern: ['m', 'y']
              }}
              name="expiryDate"
              id="expiry"
              label="CardExpiryLable"
              placeHolder="CardExpiryPlaceholder"
              autocomplete="cc-exp"
              inputValidation={value => validateExpiryDate(value)}
            />
            <CleaveInput
              options={{
                blocks: [4],
                numericOnly: true
              }}
              name="cardSecurityCode"
              id="cvv"
              label="CardSecurityCodeLabel"
              placeHolder="CardSecurityPlaceholder"
              type="password"
              autocomplete="cc-csc"
              inputValidation={value => validateCVV(value, 'CVVRequired')}
            />
          </div>
          {data.saveCustomerCards && (
            <div className="save-card-wrapper">
              <input
                ref={register(saveCard)}
                type="checkbox"
                id="saveCard"
                name="saveCard"
              />
              <label htmlFor="saveCard">{t('saveThisCardLabel')}</label>
            </div>
          )}
          {hasSavedCards && (
            <div onClick={() => setAddNewCard(false)}>
              <p className="add-new-card-button">{t('useSavedCardLabel')}</p>
            </div>
          )}
        </>
      )}
      {method.additionalFields && <AdditionalFields />}
      <input
        className="button-submit"
        type="submit"
        value={t('ButtonSubmitLabel')}
      />
      <SecureLogos />
    </div>
  );
};
